// extracted by mini-css-extract-plugin
export var bottomImgMargin = "ImageGrid-module--bottomImgMargin--E+GRU";
export var fillWidthImage = "ImageGrid-module--fillWidthImage--l8+0f";
export var fourtyWidthImage = "ImageGrid-module--fourtyWidthImage--aaOHr";
export var fullWidthImage = "ImageGrid-module--fullWidthImage--sgDaJ";
export var grid = "ImageGrid-module--grid--fHPei";
export var gridWidth = "950px";
export var image = "ImageGrid-module--image--usTNu";
export var leftImgMargin = "ImageGrid-module--leftImgMargin--WbsX3";
export var rightImgMargin = "ImageGrid-module--rightImgMargin--TAqpm";
export var topImgMargin = "ImageGrid-module--topImgMargin--T7Teg";